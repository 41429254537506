import jQuery from 'jquery';
import 'bootstrap';
import './style.scss';

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import executiveLeadership from './routes/executiveLeadership';

import WebFont from 'webfontloader';

import 'owl.carousel';

import { TweenMax, TimelineLite, Power2, Elastic, CSSPlugin } from 'gsap/TweenMax'

import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'


// Add all icons to the library so you can use it in your page
library.add(fab, fas, far, fal);

// Axios

import axios from "axios";

window.axios = axios;

// Vue
import Vue from "vue/dist/vue.js";

window.Vue = Vue;

import leadershipCard from "./components/leadership-card.vue";
Vue.component("leadership-card", leadershipCard);

dom.i2svg();

dom.watch({
    autoReplaceSvgRoot: document,
    observeMutationsRoot: document.body
});

// Initialize webfonts
WebFont.load({
    google: {
        families: ['Droid Sans', 'Source Sans Pro', 'Zilla Slab']
    }
});

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
    /** All pages */
    common,
    /** Home page */
    home,
    executiveLeadership
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());