<template>
    <div class="exec-card">
        <div class="meta">
            <div
                class="exec-card-photo"
                v-bind:style='{ backgroundImage: "url(" + leader.photo + ")", }'
            >
            </div>
        </div>
        <div class="description">
            <h3> {{ leader.name }} </h3>
            <h2> {{ leader.position }} </h2>
            <ul v-for="item in leader.description">
                <li>{{ item }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["leader"],

        data() {
            return {};
        },

        computed: {},

        methods: {}
    };
</script>

<style lang="scss">
    @import "./src/styles/_global-vars.scss";
    .exec-card {
        display: flex;
        flex-direction: column;
        margin: 1rem auto;
        box-shadow: 0 .25rem .25rem rgba($black, .25);
        background: $white;
        line-height: 1.4;
        overflow: hidden;
        z-index: 0;
        border-bottom: 5px solid $theme-primary;
        .meta {
            position: relative;
            z-index: 0;
            height: 400px;
            min-height: 400px;
        }
        &-photo {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-size: cover;
            background-position: center top;
        }
        .description {
            padding: 1rem 2rem;
            background: $white;
            position: relative;
            z-index: 1;
            h3 {
                font-size: 1.75rem;
            }
            h2 {
                font-size: 1rem;
                font-weight: 400;
                text-transform: uppercase;
                color: $theme-dark-gray;
                position: relative;
                margin-bottom: 1.5rem;
                &:after {
                    content: "";
                    position: absolute;
                    height: 5px;
                    background: $theme-primary;
                    width: 50px;
                    left: 0;
                    bottom: -1rem;
                }
            }
        }
        p {
            position: relative;
            margin-top: 1.5rem;
            &:before {
                content: "";
                position: absolute;
                height: 5px;
                background: $theme-primary;
                width: 50px;
                top: -0.75rem;
            }
        }
        ul {
            padding: 0;
            list-style: none;
            line-height: 2rem;
            li::before {
                content: '\203a';
                font-weight: 900;
                margin-right: .5rem;
            }
        }

        @media (min-width: 640px) {
            flex-direction: row;
            max-width: 1200px;
            .meta {
                flex-basis: 40%;
                height: auto;
            }
            .description {
                flex-basis: 60%;
                &:before {
                    transform: skewX(-3deg);
                    content: "";
                    background: $theme-light-gray;
                    width: 50px;
                    position: absolute;
                    left: -2.5rem;
                    top: 0;
                    bottom: 0;
                    z-index: -1;
                }
            }
            &.alt {
                flex-direction: row-reverse;
                .description {
                    &:before {
                        left: inherit;
                        right: -10px;
                        transform: skew(3deg)
                    }
                }
            }
        }
    }
</style>
