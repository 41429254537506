// import jQuery from "jquery";
import leadershipCard from '../components/leadership-card.vue';

export default {
    init() {
        console.log('in executiveLeadership.js');
    },
    finalize() {
        new Vue({
            components: {
                leadershipCard,
            },

            el: "#leadership-app",

            data: {
                leaders: []
            },

            mounted() {
                axios.get("/wp-json/cpl/v1/leadership").then(response => {
                    console.log('response', response.data);
                    this.leaders = response.data;
                });
            },

            methods: {

            }
        });
    }
};
